import cn from "clsx";
import Image from "next/image";
import { Trans, useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { Button, Loader, RichText, Text } from "@components/ui";
import { useUI } from "@middleware/hooks";
import { getFileApiPath } from "@middleware/helpers";
import { IngredientsAdditionalInformation } from "./IngredientsAdditionalInformation/IngredientsAdditionalInformation";
import style from "./ProductModal.module.scss";
import { NutriscoreImage } from "../NutriscoreImage/NutriscoreImage";

export const ProductModal = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const { productToShow } = useUI();

  useEffect(() => {
    if (productToShow !== undefined) {
      setLoading(false);
    }
  }, [productToShow]);

  return (
    <>
      {loading ? (
        <div className="loaderContainer p-3 text-center">
          <Loader type="clip" color="#000" />
        </div>
      ) : (
        productToShow && (
          <div
            className={cn(style.boxInfo, {
              [style.newProd]: productToShow.isNew,
            })}
          >
            <div className={style.modalContent}>
              <div className={style.row}>
                <div className={style.col}>
                  <div className={style.thumbnail}>
                    <Image
                      src={getFileApiPath(productToShow.image)}
                      alt=""
                      width={378}
                      height={378}
                    />
                  </div>
                  {productToShow.nutriscore.length > 0 && (
                    <div className={style.nutriscore}>
                      <NutriscoreImage
                        nutriscore={productToShow.nutriscore[0]}
                      />
                    </div>
                  )}
                </div>
                <div className={style.col}>
                  <div className={style.wrap}>
                    <Text
                      variant="sectionHeading"
                      className={style.name}
                      html={productToShow.name}
                    />

                    <div className={style.SDesc}>
                      <RichText>
                        <Text variant="body" html={productToShow.description} />
                      </RichText>
                    </div>

                    <div className={style.PTabs}>
                      <div className={style.PHeaderTabs}>
                        <ul>
                          <li className={cn({ [style.active]: tab === 0 })}>
                            <Button onClick={() => setTab(0)} puce={false}>
                              <Trans
                                t={t}
                                i18nKey={"popProduct.tips"}
                                components={{
                                  br: <br />,
                                }}
                              />
                            </Button>
                          </li>
                          <li className={cn({ [style.active]: tab === 1 })}>
                            <Button onClick={() => setTab(1)} puce={false}>
                              <Trans
                                t={t}
                                i18nKey={"popProduct.ingredients"}
                                components={{
                                  br: <br />,
                                }}
                              />
                            </Button>
                          </li>
                          <li className={cn({ [style.active]: tab === 2 })}>
                            <Button onClick={() => setTab(2)} puce={false}>
                              {t("popProduct.nutritional")}
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className={style.PBodyTabs}>
                        <div
                          className={cn(style.PTab, {
                            [style.active]: tab === 0,
                          })}
                        >
                          <RichText>
                            <Text
                              variant="p"
                              html={productToShow.preparation}
                            />
                          </RichText>
                        </div>
                        <div
                          className={cn(style.PTab, {
                            [style.active]: tab === 1,
                          })}
                        >
                          <IngredientsAdditionalInformation
                            product={productToShow}
                          />
                        </div>
                        <div
                          className={cn(style.PTab, style.nutritional, {
                            [style.active]: tab === 2,
                          })}
                        >
                          <Text
                            variant="body"
                            html={productToShow.nutritional}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
