import { FIRST_WEIGHT_TO_LOSE, HEALTY_IMC } from "@middleware/constants";
import {
  IObject,
  IProgram,
  TBilanData,
  TBmi,
  TResponseBmi,
  TResponseResultBilan,
  TResultBilan,
  TResultsBilanDataItem,
} from "@middleware/types";

export const serializeResultBilanData = (obj: IObject) =>
  Object.entries(obj).reduce((acc: TResultsBilanDataItem, [key, value]) => {
    const fieldToExclude = ["email", "phone", "name", "agreementCheck"];
    if (!fieldToExclude.includes(key)) {
      acc[key] = {
        question: key,
        answer: value,
      };
    }

    return acc;
  }, {});

export const getRecommendedBilanProgram = (
  programs: IProgram[],
  recomendedProgramCode: string,
) => {
  return programs.find((program) => program.code === recomendedProgramCode);
};

export const getAge = (date: string) => {
  const birthday = new Date(date);
  const today = new Date();
  const age = today.getFullYear() - birthday.getFullYear();

  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    return age - 1;
  }

  return age;
};

export const serializeResultBilan = (
  response: TResponseResultBilan,
): TResultBilan => {
  const serializeBmi = (responseBmi: TResponseBmi): TBmi => {
    return {
      originalValue: responseBmi.original_value,
      value: responseBmi.value,
      message: responseBmi.message,
    };
  };

  return {
    qls: response.qls,
    bmi: {
      height: response.bmi.height,
      current: serializeBmi(response.bmi.current),
      desired: serializeBmi(response.bmi.desired),
      recommended: serializeBmi(response.bmi.recommended),
      healthy: serializeBmi(response.bmi.healthy),
    },
    weightGoal: {
      height: response.weightGoal.height,
      currentWeight: response.weightGoal.currentWeight,
      desired: response.weightGoal.desired,
      bmiBasedRecommendation: response.weightGoal.bmiBasedRecommendation,
    },
    customerId: response.customerId,
  };
};

export const getFirstObjectiveParagraph = (bilanData: TBilanData): string => {
  const { weightToLoss, desiredImc } = bilanData;
  if (weightToLoss < FIRST_WEIGHT_TO_LOSE && desiredImc > HEALTY_IMC)
    return "pages.myFirstObjectiveText1";
  else if (weightToLoss > FIRST_WEIGHT_TO_LOSE && desiredImc > HEALTY_IMC)
    return "pages.myFirstObjectiveText2";
  else if (weightToLoss < FIRST_WEIGHT_TO_LOSE && desiredImc < HEALTY_IMC)
    return "pages.myFirstObjectiveText3";
  else return "pages.myFirstObjectiveText4";
};

export const getSecondObjectiveParagraph = (desiredImc: number): string => {
  if (desiredImc > HEALTY_IMC) return "pages.mySecondObjectiveText1";

  return "pages.mySecondObjectiveText2";
};

export const generateBilanData = (
  resultBilanData: TResultBilan,
): TBilanData => {
  const healtyWeight = Math.round(
    HEALTY_IMC * Math.pow(resultBilanData.bmi.height / 100, 2),
  );
  const data = {
    currentWeight: resultBilanData.weightGoal.currentWeight,
    recommendedWeight:
      resultBilanData.weightGoal.bmiBasedRecommendation.weightGoal,
    healtyWeight,
    desiredWeight: resultBilanData.weightGoal.desired.weightGoal,
    height: resultBilanData.bmi.height,
    currentImc: resultBilanData.bmi.current.originalValue,
    desiredImc: resultBilanData.bmi.desired.originalValue,
    recommendedImc: resultBilanData.bmi.recommended.originalValue,
    healthyImc: resultBilanData.bmi.healthy.originalValue,
    weightToLoss: resultBilanData.weightGoal.desired.weightLossGoal,
    firstWeightToLoss:
      resultBilanData.weightGoal.bmiBasedRecommendation.weightLossGoal,
  };

  return data;
};
