import WrapApi from "@middleware/helpers/api/wrapApi.class";
import { serializeResultBilan } from "@middleware/helpers/global/bilan";
import {
  TResponseResultBilan,
  TResultBilan,
  TResultsBilanData,
} from "@middleware/types";
import { AxiosResponse } from "axios";

export default class Bilan extends WrapApi {
  getResultBilan = async (
    customerId: number,
  ): Promise<TResultBilan | undefined> => {
    const result = await this.getResource(
      `shop/customers/${customerId}/evaluation`,
    );

    if (result.status !== 200) return;

    return serializeResultBilan(
      result.data as TResponseResultBilan,
    ) as TResultBilan;
  };

  saveBilanResult = async (data: TResultsBilanData): Promise<AxiosResponse> => {
    return await this.postResource(`shop/customers/evaluation/`, data);
  };
}
