import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { URL_PAGE_IMPERSONATE } from "@middleware/constants";
import { useAuth } from "./useAuth";
import { useCart } from "./useCart";
import { useGlobalMessaging } from "./useGlobalMessaging";

export const useClearAutentification = () => {
  const { pathname } = useRouter();
  const { logoutUser } = useAuth();
  const { getNewCart } = useCart();
  const { t } = useTranslation("header");
  const { setMessage } = useGlobalMessaging();

  const resetAutentification = async () => {
    logoutUser();
    setMessage({ text: t("login.logoutSucces"), type: "SUCCESS" });
    if (pathname !== URL_PAGE_IMPERSONATE) {
      await getNewCart();
    }
  };

  return { resetAutentification };
};
